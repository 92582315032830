html,
body {
  margin: 0;
  padding: 0;
  background-color: black; /* Set background to black */
  height: 100%; /* Ensure the body takes the full height of the viewport */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  color: white; /* Optional: Set default text color to white for contrast */
  overscroll-behavior: none;
}

#root {
  height: 100%; /* Ensure the root div takes the full height of the viewport */
}

@keyframes pulseSmall {
  0% {
    text-shadow: none;
  }
  25% {
    text-shadow: 0 0 2px #fff1d5, 0 0 3px #ffd700, 0 0 4px #000000;
  }
  50% {
    text-shadow: 0 0 2px #fff1d5;
  }
  75% {
    text-shadow: none;
  }
  100% {
    text-shadow: none;
  }
}

@keyframes pulseLarge {
  0% {
    text-shadow: none;
  }
  25% {
    text-shadow: 0 0 6px #fff1d5, 0 0 10px #ffd700, 0 0 12px #000000;
  }
  50% {
    text-shadow: 0 0 2px #fff1d5;
  }
  75% {
    text-shadow: none;
  }
  100% {
    text-shadow: none;
  }
}

/* For high-DPI screens (like iPhones and Retina displays) */
@media screen and (max-width: 600px), screen and (min-resolution: 2dppx) {
  .pulse-animation-fhe {
    animation: pulseSmall 10s infinite 2s !important;
  }
}

@media screen and (min-width: 601px) {
  .pulse-animation-fhe {
    animation: pulseLarge 10s infinite 2s !important;
  }
}

/* For DEX animation */
@media screen and (max-width: 600px), screen and (min-resolution: 2dppx) {
  .pulse-animation-dex {
    animation: pulseSmall 10s infinite 6s !important;
  }
}

@media screen and (min-width: 601px) {
  .pulse-animation-dex {
    animation: pulseLarge 10s infinite 6s !important;
  }
}

/* In your CSS file */
.responsive-text {
  font-size: 20px;
}

@media (min-width: 600px) {
  .responsive-text {
    font-size: 28px;
  }
}

@media (min-width: 900px) {
  .responsive-text {
    font-size: 28px;
  }
}

@media (min-width: 1200px) {
  .responsive-text {
    font-size: 28px;
  }
}

.responsive-subtitle {
  font-size: 20px;
  font-weight: 600;
}

@media (min-width: 600px) {
  .responsive-subtitle {
    font-size: 28px;
  }
}

@media (min-width: 900px) {
  .responsive-subtitle {
    font-size: 28px;
  }
}

@media (min-width: 1200px) {
  .responsive-subtitle {
    font-size: 24px;
  }
}

.responsive-KaTeX {
  font-size: 18px;
  font-family: "KaTeX_SansSerif";
}

@media (min-width: 600px) {
  .responsive-KaTeX {
    font-size: 22px;
    font-family: "KaTeX_SansSerif";
  }
}

@media (min-width: 900px) {
  .responsive-KaTeX {
    font-size: 22px;
    font-family: "KaTeX_SansSerif";
  }
}

@media (min-width: 1200px) {
  .responsive-KaTeX {
    font-size: 22px;
    font-family: "KaTeX_SansSerif";
  }
}

@media print {
  .MuiTypography-root {
    color: black !important;
    font-size: 16px;
    page-break-after: auto;
    page-break-before: auto;
  }
  div,
  p,
  section,
  article {
    page-break-after: auto;
    page-break-before: auto;
  }

  .MuiBox-root {
    height: "auto" !important;
    overflow: visible !important;
    /* page-break-inside: avoid; */
    page-break-after: auto;
    page-break-before: auto;
  }

  .MuiStack-root {
    overflow: visible !important;
  }
  body {
    overflow: visible !important;
    height: "auto !important";
  }

  html {
    overflow: "visible !important";
  }

  * {
    box-shadow: "none !important";
    overflow: "visible !important";
    max-height: "none !important";
  }

  .MuiButton-root {
    display: none !important;
  }

  .scrollable-container {
    overflow: "visible !important";
  }

  .header.navbar {
    display: none !important; /* Hide the AppBar when printing */
  }

  .MuiAppBar-root {
    display: none !important;
  }
}
